import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { orderBy } from 'lodash'
import cx from 'classnames'

import Layout from '../components/Layout'
import Image from '../components/Image'
import NewsComponent from '../components/NewsComponent'
import PublicationsComponent from '../components/PublicationsComponent'
import OpenSourceSoftwareComponent from '../components/OpenSourceSoftwareComponent'
import LogoGrid from '../components/LogoGrid'
import smoothScroll from '../components/utils/smoothScroll'
import formatId from '../components/utils/formatId'
import FeaturedBlogPost from '../components/FeaturedBlogPost'
import SimpleBlogPost from '../components/SimpleBlogPost'

export const ResourcesPageTemplate = ({
    title,
    introduction,
    highlightedResourcesItems,
    newsHeading,
    newsItems,
    featuredLogoGrid,
    publicationsHeading,
    scientificPublications,
    openSourceSoftwareHeading,
    openSourceSoftware,
    logoGrid,
    featuredBlogPost,
    posts,
}) => {
    posts = posts ? posts.slice(0, 2) : null

    return (
        <div className={cx('my-60 lg:mb-120')}>
            <div className={cx('max-w-1264 mx-auto px-24 lg:px-32')}>
                <section>
                    <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                    {introduction && (
                        <p
                            className={cx(
                                'lg:w-1/2 text-21 leading-snug mt-24'
                            )}
                        >
                            {introduction}
                        </p>
                    )}
                </section>

                {highlightedResourcesItems && (
                    <section className={cx('mt-32 lg:mt-64 -mx-8 lg:-mx-24')}>
                        <div
                            className={cx(
                                'border-8 border-gray-100 rounded-24'
                            )}
                        >
                            <ul
                                className={cx(
                                    'flex flex-wrap pt-60 pb-20 text-center'
                                )}
                            >
                                {highlightedResourcesItems.map(
                                    (
                                        {
                                            title,
                                            icon,
                                            description,
                                            sectionName,
                                        },
                                        i
                                    ) => (
                                        <li
                                            className={cx(
                                                'w-full px-32 mb-60 md:w-1/2 lg:w-1/4 group'
                                            )}
                                            key={`${title}-${i}`}
                                        >
                                            <button
                                                onClick={() =>
                                                    smoothScroll(
                                                        formatId(sectionName)
                                                    )
                                                }
                                            >
                                                {icon && (
                                                    <Image
                                                        image={icon}
                                                        alt={title}
                                                        className={cx(
                                                            'w-90 mx-auto mb-24'
                                                        )}
                                                    />
                                                )}

                                                <div>
                                                    <strong
                                                        className={cx(
                                                            'inline-block hd-md mb-16 group-hover:shadow-underline duration-200'
                                                        )}
                                                    >
                                                        {title}
                                                    </strong>
                                                    {description && (
                                                        <p>{description}</p>
                                                    )}
                                                </div>
                                            </button>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </section>
                )}

                {newsItems && (
                    <NewsComponent
                        newsHeading={newsHeading}
                        newsItems={newsItems}
                        className="mt-80"
                    />
                )}

                {featuredLogoGrid && (
                    <LogoGrid logoGrid={featuredLogoGrid} className="mt-80" />
                )}

                {scientificPublications && (
                    <PublicationsComponent
                        publicationsHeading={publicationsHeading}
                        scientificPublications={scientificPublications}
                        className="mt-80"
                    />
                )}

                {openSourceSoftware && (
                    <OpenSourceSoftwareComponent
                        openSourceSoftwareHeading={openSourceSoftwareHeading}
                        openSourceSoftware={openSourceSoftware}
                    />
                )}

                {logoGrid && <LogoGrid logoGrid={logoGrid} className="mt-60" />}
            </div>

            <div id="blog">
                {featuredBlogPost && featuredBlogPost.node && (
                    <FeaturedBlogPost
                        post={featuredBlogPost.node.frontmatter}
                        slug={featuredBlogPost.node.fields.slug}
                        isExpandedComponent={true}
                        className="mt-80"
                    />
                )}

                {posts && posts.length > 1 && (
                    <section className={cx('bg-purple-800 py-80 lg:py-128')}>
                        <ul
                            className={cx(
                                'grid lg:grid-cols-2 gap-40 max-w-1264 mx-auto px-24 md:px-40 xl-px-0'
                            )}
                        >
                            {posts.map((post) => (
                                <li key={post.node.id}>
                                    <SimpleBlogPost
                                        post={post.node.frontmatter}
                                        slug={post.node.fields.slug}
                                    />
                                </li>
                            ))}
                        </ul>
                    </section>
                )}
            </div>
        </div>
    )
}

ResourcesPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    highlightedResourcesItems: PropTypes.array,
    newsHeading: PropTypes.string,
    newsItems: PropTypes.array,
    publicationsHeading: PropTypes.string,
    scientificPublications: PropTypes.array,
    openSourceSoftwareHeading: PropTypes.string,
    openSourceSoftware: PropTypes.array,
    logoGrid: PropTypes.shape({
        heading: PropTypes.string,
        logoGridImages: PropTypes.array,
    }),
    featuredLogoGrid: PropTypes.shape({
        heading: PropTypes.string,
        logoGridImages: PropTypes.array,
    }),
    featuredBlogPost: PropTypes.object,
    posts: PropTypes.array,
}

const ResourcesPage = ({ data }) => {
    const { frontmatter } = data.page
    const pressReleases = data.pressReleases.edges.map((pr) => {
        pr.node.frontmatter.url = pr.node.fields.slug

        return pr
    })
    const newsItems = data.newsItems.edges
        .concat(pressReleases)
        .sort((a, b) =>
            a.node.frontmatter.date < b.node.frontmatter.date ? 1 : -1
        )
        .slice(0, 4)
    // NOTE: Order based on filepath (new > old)
    //         and grab the first 8
    const scientificPublications = orderBy(
        data.scientificPublications.edges,
        ['node.fileAbsolutePath'],
        ['desc']
    ).slice(0, 8)
    const openSourceSoftware = data.openSourceSoftware.edges

    const authors = data.authors.edges
    let posts = data.posts.edges
    let featuredBlogPost = posts[0]

    posts.forEach((post) => {
        let author = post.node.frontmatter.author
        let authorMatch = authors.find(
            (item) => item.node.frontmatter.authorName === author
        )
        if (authorMatch)
            post.node.frontmatter.authorData = authorMatch.node.frontmatter

        if (post.node.frontmatter.title === frontmatter.featuredBlogPost) {
            featuredBlogPost = post
            posts = posts.filter((p) => p !== featuredBlogPost)
        }
    })

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <ResourcesPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                highlightedResourcesItems={
                    frontmatter.highlightedResourcesItems
                }
                newsHeading={frontmatter.newsHeading}
                newsItems={newsItems}
                featuredLogoGrid={frontmatter.featuredLogoGrid}
                publicationsHeading={frontmatter.publicationsHeading}
                scientificPublications={scientificPublications}
                openSourceSoftwareHeading={
                    frontmatter.openSourceSoftwareHeading
                }
                openSourceSoftware={openSourceSoftware}
                logoGrid={frontmatter.logoGrid}
                featuredBlogPost={featuredBlogPost}
                posts={posts}
            />
        </Layout>
    )
}

ResourcesPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.object,
        newsItems: PropTypes.object,
        scientificPublications: PropTypes.object,
        openSourceSoftware: PropTypes.object,
        posts: PropTypes.object,
        authors: PropTypes.object,
        pressReleases: PropTypes.object,
    }),
}

export default ResourcesPage

export const pageQuery = graphql`
    query ResourcesPageTemplate {
        page: markdownRemark(
            frontmatter: { templateKey: { eq: "resources-page" } }
        ) {
            frontmatter {
                title
                introduction
                highlightedResourcesItems {
                    sectionName
                    icon {
                        extension
                        publicURL
                    }
                    title
                    description
                }
                newsHeading
                featuredLogoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                publicationsHeading
                openSourceSoftwareHeading
                logoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                featuredBlogPost
            }
        }
        newsItems: allMarkdownRemark(
            limit: 4
            sort: { order: DESC, fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(collections/news)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        date
                        source
                        title
                        url
                    }
                }
            }
        }
        scientificPublications: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                fileAbsolutePath: {
                    regex: "/(collections/scientific-publications)/"
                }
            }
        ) {
            edges {
                node {
                    fileAbsolutePath
                    frontmatter {
                        author
                        description
                        publication
                        title
                        url
                        year
                    }
                }
            }
        }
        openSourceSoftware: allMarkdownRemark(
            limit: 4
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                fileAbsolutePath: {
                    regex: "/(collections/open-source-software)/"
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        language
                        languageLogo {
                            extension
                            publicURL
                        }
                        description
                        packageName
                        url
                    }
                }
            }
        }
        pressReleases: allMarkdownRemark(
            limit: 4
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                frontmatter: { templateKey: { eq: "press-release-page" } }
            }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        author
                        date
                        source
                        url
                        summary
                    }
                }
            }
        }
        posts: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: { frontmatter: { templateKey: { eq: "blog-post-page" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        author
                        date
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1440, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        summary
                    }
                }
            }
        }
        authors: allMarkdownRemark(
            sort: { fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(_authors)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        authorName
                        authorTitle
                        authorImage {
                            childImageSharp {
                                fluid(maxWidth: 100, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
